import React, { useContext, useEffect } from "react";
import GlobalContext from "services/global-context";
import Loader from "components/loader";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { setCurrentPanel, currentCustomer, loadingCustomer } = useContext(GlobalContext);

  useEffect(() => {
    if (loadingCustomer) return;

    if (currentCustomer === null) {
      setCurrentPanel("login");
    } else {
      setCurrentPanel(null);
    }
  }, [currentCustomer, loadingCustomer]);


  if (!currentCustomer && loadingCustomer) return <Loader full={true} />;
  if (!currentCustomer && !loadingCustomer) return <></>;

  return <Component {...rest} />;
};
export default PrivateRoute;
