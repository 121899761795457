// extracted by mini-css-extract-plugin
export var card = "styles-mod--card--3Dt7d";
export var cardHeader = "styles-mod--card-header--2OHA1";
export var accordionIcon = "styles-mod--accordion-icon--3va0h";
export var orderDate = "styles-mod--order-date--3Ha9A";
export var orderTotal = "styles-mod--order-total--3uhFV";
export var sub = "styles-mod--sub--3-AKc";
export var orderBody = "styles-mod--order-body--2kmZt";
export var orderSteps = "styles-mod--order-steps--1d8ET";
export var stepNumber = "styles-mod--step-number--2bwNL";
export var stepNumberCurrent = "styles-mod--step-number-current--2De1e";
export var stepDescription = "styles-mod--step-description--2MPLY";
export var lineItem = "styles-mod--line-item--N3MJi";
export var lineItemDescription = "styles-mod--line-item-description--DwxBl";
export var variantImage = "styles-mod--variant-image--1Zt5c";