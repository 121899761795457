import React, { useContext } from "react";
import AccountLayout from "components/account-layout";
import * as styles from "./styles.mod.scss";
import { PointStatus } from "../collect-points";
import CustomerInitials from "../customer-initials";
import GlobalContext from "services/global-context";
import { OfferBox } from "../overview";
import SpecialOffers from "assets/images/account/special-offers.png";
import Classic from "assets/images/account/classic.svg";
import Premium from "assets/images/account/premium.svg";
import VIP from "assets/images/account/vip.svg";
import { Check2 } from "react-bootstrap-icons";

const tierMap = {
  "Classic": Classic,
  "Premium": Premium,
  "VIP": VIP
};

const MemberStatus = ({ tier }) => {
  return <div className={styles.memberStatus}>
    <div className={styles.memberTier}>
      <span>Du bist</span>
      <div>{tier} Kunde</div>
    </div>
    <div className={styles.memberTierIcon}>
      <img src={tierMap[tier]} />
    </div>
  </div>;
};

const VIPStatus = () => {
  const { currentCustomer, loyaltyLion } = useContext(GlobalContext);

  return (
    <AccountLayout
      headerChildren={
        <div className="col">
          {loyaltyLion?.tier && <MemberStatus tier={loyaltyLion?.tier} />}
        </div>
      }>
      <div className={styles.wrap}>
        <div className="col-md-6">
          <div className={styles.box}>
            <div>
              <PointStatus title="Du hast insgesamt" className={styles.pointStatus} />

              <div className={styles.field}>
                <label>Mitglied seit:</label>
                <span>{new Date(loyaltyLion?.customer?.createdAt).toLocaleDateString()}</span>
              </div>
              <div className={styles.field}>
                <label>Level:</label>
                <span>{loyaltyLion?.tier}</span>
              </div>

            </div>
            <CustomerInitials customer={currentCustomer} />
          </div>

        </div>
        <div className="col-md-6">
          <OfferBox
            img={SpecialOffers}
            title="Spezielle Angebote"
            subtitle="Freunde werben und tolle Prämien erhalten"
            ctaCopy="Jetzt Entdecken"
            ctaLink=""
            className={styles.offerBox}
          />
        </div>
      </div>
      <div className={styles.wrap}>
        <div className="col-12">
          <h3 className={styles.title}>Olav Club Ränge</h3>
          <div className={styles.subtitle}>Dein Spice Club Rang basiert auf Deinen gesammelten Punkte der letzten 12 Monate.</div>

          <div className={styles.tiers}>
            <div className={[styles.tier, styles.tierClassic, loyaltyLion?.tier === "Classic" ? styles.currentTier : ""].join(" ")}>
              <div>
                {loyaltyLion?.tier === "Classic" && <CustomerInitials customer={currentCustomer} className={styles.initials} />}
                <div>
                  <img src={Classic} />
                  <div className={styles.tierName}>Classic</div>
                  <div className={styles.tierPoints}>ab 0</div>
                </div>
              </div>
            </div>
            <div className={[styles.tier, styles.tierPremium, loyaltyLion?.tier === "Premium" ? styles.currentTier : ""].join(" ")}>
              <div>
                {loyaltyLion?.tier === "Premium" && <CustomerInitials customer={currentCustomer} className={styles.initials} />}
                <div>
                  <img src={Premium} />
                  <div className={styles.tierName}>Premium</div>
                  <div className={styles.tierPoints}>ab 500</div></div>
              </div>
            </div>
            <div className={[styles.tier, styles.tierVip, loyaltyLion?.tier === "VIP" ? styles.currentTier : ""].join(" ")}>
              <div>
                {loyaltyLion?.tier === "VIP" && <CustomerInitials customer={currentCustomer} className={styles.initials} />}

                <div>
                  <img src={VIP} />
                  <div className={styles.tierName}>VIP</div>
                  <div className={styles.tierPoints}>ab 2000</div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <h3 className={styles.title}>Rangsystem Treueprogram</h3>
          <div className={styles.tableWrap}>

            <table className={styles.tierTable}>
              <thead>
                <tr>
                  <th>Vorteile</th>
                  <th>Classic</th>
                  <th>Premium</th>
                  <th>VIP</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                      Erfahre als Erster von neuen Produkten oder Angeboten
                  </td>
                  <td><Check2 /></td>
                  <td><Check2 /></td>
                  <td><Check2 /></td>
                </tr>
                <tr>
                  <td>
                      Spezielle Angebote nur für Olavisten
                  </td>
                  <td><Check2 /></td>
                  <td><Check2 /></td>
                  <td><Check2 /></td>
                </tr>
                <tr>
                  <td>
                      Produkttester für neue Produkte werden
                  </td>
                  <td></td>
                  <td><Check2 /></td>
                  <td><Check2 /></td>
                </tr>
                <tr>
                  <td>
                      Punkte pro Kauf
                  </td>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>
                      Meet&Greet mit Haya Molcho gewinnen (Verlosung)
                  </td>
                  <td></td>
                  <td></td>
                  <td><Check2 /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};

export default VIPStatus;
