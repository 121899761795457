import React, { useContext, useMemo, useRef } from "react";
import AccountLayout from "components/account-layout";
import { PointStatus } from "../collect-points";
import * as styles from "./styles.mod.scss";
import Knifes from "assets/images/account/knifes@2x.png";
import GlobalContext from "services/global-context";

const mapRewards = (loyaltyLion) => {
  console.log("mapping rewards");
  return (loyaltyLion?.program?.rewards|| []).map((reward) => ({
    id: reward.id,
    title: reward.title,
    points: loyaltyLion.program.loyaltyTiers.find((tier) => tier.id === loyaltyLion.customer.loyaltyTierMembership.loyaltyTierId)?.rewardOverrides.find((override) => override.rewardId === reward.id)?.cost || reward.point_cost
  })).map((reward) => ({
    ...reward,
    redeemable: loyaltyLion.customer.pointsRedeemable >= reward.points
  }));
};
const Reward = (props) => {
  const { id, points, title, redeemable, onClick } = props;

  const handleClick = (e) => {
    e.preventDefault();
    onClick(id);

    return false;
  };
  return <div className={styles.rewardWrap}>
    <div className={styles.reward}>
      <img src={Knifes} />
      <div className={styles.rewardDescription}>
        <span>Ab {points} Punkten:</span>
        <strong>{title}</strong>

        {redeemable && <a href="#" onClick={handleClick}>Einlösen</a>}
        {!redeemable && <a href="#" className={styles.disabledLink}>Mehr Punkte notwendig</a>}
      </div>

    </div>
  </div>;
};

const RedeemPoints = () => {
  const { loyaltyLion } = useContext(GlobalContext);
  const rewardsRef = useRef(null);

  const rewards = useMemo(() => mapRewards(loyaltyLion), [loyaltyLion]);
  console.log("rewards", rewards);

  const handleRewardClick = (rewardId) => {
    if (rewardsRef?.current) {
      rewardsRef.current.querySelector(`[data-reward-id="${rewardId}"] .lion-action-button`)?.click();
    }
  };

  return <AccountLayout
    title="Punkte einlösen"
    subtitle="Sammel Punkte und erhalte tolle Angebote"
    headerChildren={
      <PointStatus />
    }
  >
    <div className={styles.wrap}>
      <div className={styles.title}>
        <h3>Gutscheine</h3>
      </div>
      {rewards.map((reward) => <Reward points={reward.points} title={reward.title} key={reward.id} id={reward.id} onClick={handleRewardClick} redeemable={reward.redeemable} />)}
      <div data-lion-rewards-list ref={rewardsRef}></div>
    </div>
  </AccountLayout>;
};

export default RedeemPoints;
