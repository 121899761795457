import React, { useContext, useEffect } from "react";
import GlobalContext from "services/global-context";
import * as styles from "./styles.mod.scss";
import { Link } from "@reach/router";
import CustomerInitials from "../account/customer-initials";
import useLoyaltyLion from "services/use-loyalty-lion";
import RoundedLoader from "components/rounded-loader";
import Classic from "assets/images/account/classic.svg";
import Premium from "assets/images/account/premium.svg";
import VIP from "assets/images/account/vip.svg";
import { navigate } from "gatsby";
import Loader from "components/loader";

const tierMap = {
  "Classic": Classic,
  "Premium": Premium,
  "VIP": VIP
};

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return { className: (isCurrent ? styles.activeLink : "") };
    }}
  />
);

const CustomerTier = ({ tier }) => {
  return <div className={styles.customerTier}>
    <img src={tierMap[tier]} />
    {tier} Kunde
  </div>;
};

const AccountLayout = (props) => {
  const { children, title, subtitle, headerChildren } = props;
  const { currentCustomer, setLoyaltyLion, logOut, setCurrentPanel, loadingCustomer } = useContext(GlobalContext);
  const lionConfig = useLoyaltyLion(currentCustomer);

  useEffect(() => {
    setLoyaltyLion(lionConfig);
  }, [lionConfig]);

  useEffect(() => {
    if (loadingCustomer) return;

    if (currentCustomer === null) {
      setCurrentPanel("login");
    } else {
      setCurrentPanel(null);
    }
  }, [currentCustomer, loadingCustomer]);

  if (!currentCustomer && loadingCustomer) return <Loader full={true} />;
  if (!currentCustomer && !loadingCustomer) return <></>;

  const handleLogout = (evt) => {
    logOut();


    navigate("/");

    evt.preventDefault();

    return false;
  };

  return (
    <div className={styles.bg}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <div className="col-md-3">
            <div className={styles.box}>
              <div>
                <h4>Hallo, {currentCustomer.firstName}!</h4>
                <div className={styles.customerTier}>
                  {!lionConfig?.tier && <RoundedLoader />}
                  {lionConfig?.tier && <CustomerTier tier={lionConfig?.tier} /> }
                </div>
              </div>
              <CustomerInitials customer={currentCustomer} />
            </div>
          </div>
          <div className="col-md-9">
            <div className={`${styles.box} ${styles.noPadding}`}>
              {title && <div className="col">
                <h4>{title}</h4>
                <p>{subtitle}</p>
              </div>}
              {headerChildren}
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className="col-md-3 order-2 order-md-1">
            <h4>Mein Konto</h4>

            <ul className={styles.nav}>
              <li>
                <NavLink to="/account">Übersicht <div className={styles.caret}/></NavLink>
              </li>
              <li>
                <NavLink to="/account/orders">Meine Bestellungen <div className={styles.caret}/></NavLink>
              </li>
              <li>
                <NavLink to="/account/personal-information">Meine Daten <div className={styles.caret}/></NavLink>
              </li>
              <li>
                <NavLink to="/account/vip-status">Dein VIP Status <div className={styles.caret}/></NavLink>
              </li>
              <li>
                <NavLink to="/account/collect-points">Punkte sammeln <div className={styles.caret}/></NavLink>
              </li>
              <li>
                <NavLink to="/account/redeem-points">Punkte einlösen <div className={styles.caret}/></NavLink>
              </li>
              {/* <li>
                <NavLink to="/account/placeholder">Warteliste <div className={styles.caret}/></NavLink>
              </li> */}
              <li>
                <NavLink to="/account/special-offers">Spezielle Angebote <div className={styles.caret}/></NavLink>
              </li>
              <li>
                <NavLink to="/account/invite-friends">Freunde werben <div className={styles.caret}/></NavLink>
              </li>
              <li>
                <a href="" className={styles.logout} onClick={handleLogout}><i className="bi bi-box-arrow-right"/> Abmelden</a>
              </li>
            </ul>
          </div>
          <div className="col-md-9 order-1 order-md-2">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountLayout;
