import React from "react";
import AccountLayout from "components/account-layout";
import * as styles from "./styles.mod.scss";
import GiftsImage from "assets/images/account/gifts-image-1.png";
import OlavOrder from "assets/images/account/olav-order.png";
import FriendInvite from "assets/images/account/friend-invite.png";
import SpecialOffers from "assets/images/account/special-offers.png";

import FriendInviteIcon from "assets/images/account/friend-invite-icon.png";
import GiftIcon2 from "assets/images/account/gift-icon-2.png";
import { PointStatus } from "../collect-points";
import { Link } from "@reach/router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useMediaQuery } from "react-responsive";

export const OfferBox = (props) => {
  const { img, title, subtitle, label, ctaCopy, ctaLink, icon, children, className } = props;

  return (
    <div className={[styles.box, className].join(" ")}>
      <div className={styles.img}>
        <img src={img} />

        {label && <div className={styles.label}>
          {label}
        </div>}
      </div>

      <div className={styles.bottom}>
        <div className={styles.left}>
          {title &&
        <>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.subtitle}>
            {subtitle}

          </div>
        </>
          }
          {children}
          <Link to={ctaLink} className={styles.cta}>{ctaCopy}</Link>
        </div>
        <div className={styles.right}>
          {icon && <img src={icon} /> }
        </div>
      </div>
    </div>
  );
};

const Box = (props) => <div className={styles.boxWrap}><OfferBox {...props} /></div>;

const offers = [
  {
    img: GiftsImage,
    label: "Dein Geschenk",
    ctaCopy: "Bonuspunkte Einlösen",
    ctaLink: "/account/redeem-points",
    children: <PointStatus title="Du hast bereits" />,
    icon: GiftIcon2
  },
  {
    img: OlavOrder,
    title: "Bestellübersicht",
    subtitle: "Übersicht deiner Bestellungen",
    ctaCopy: "Bestellungen ansehen",
    ctaLink: "/account/orders"
  },
  {
    img: FriendInvite,
    title: "Freunde werben",
    subtitle: "Freunde werben und tolle Prämien erhalten",
    ctaCopy: "Freunde Werben",
    label: "30€ GUTSCHEIN",
    ctaLink: "/account/invite-friends",
    icon: FriendInviteIcon
  },
  {
    img: SpecialOffers,
    title: "Spezielle Angebote",
    subtitle: "Freunde werben und tolle Prämien erhalten",
    ctaCopy: "Jetzt Entdecken",
    ctaLink: "/account/special-offers"
  },
];

const AccountOverview = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1023.98px)" });

  return <AccountLayout title="Übersicht" subtitle="In Deinem Benutzerkonto kannst Du Deine Bestellungen und Kontodaten direkt verwalten.">
    <div className={styles.wrap}>
      {isMobile && <Swiper
        slidesPerView={1}
        modules={[Pagination]}
        pagination={{ clickable: true }}
        breakpoints={{ 1024: { enabled: false } }}
      >
        {offers.map((offer, index) => <SwiperSlide key={`mobile-${index}`}>
          <Box {...offer}/>
        </SwiperSlide>)}
      </Swiper>}

      {isDesktop && offers.map((offer, index) => <Box {...offer} key={index} /> )}

    </div>
  </AccountLayout>;
};

export default AccountOverview;
