import React, { useContext, useRef } from "react";
import AccountLayout from "components/account-layout";
import * as styles from "./styles.mod.scss";
import RoundedLoader from "components/rounded-loader";
import GlobalContext from "services/global-context";
import PageView from "assets/images/icons/Icon_Page-view.svg";
import Account from "assets/images/icons/Icon_Account.svg";
import FbLike from "assets/images/icons/Icon_fblike.svg";
import Insta from "assets/images/icons/Icon_insta.svg";
import Newsletter from "assets/images/icons/Icon_NL.svg";
import Birthday from "assets/images/icons/Icon_birthday.svg";

const ruleOverrides = { 
  "$signup": {
    ctaText: "Jetzt anmelden",
    icon: Account,
  },
  "$pageview": {
    title: "PageView",
    ctaText: "Zum Shop",
    icon: PageView,
  },
  "$facebook_like": { 
    title: "Facebook Follower werden",
    ctaText: "Jetz Folgen",
    icon: FbLike
  }, 
  "$instagram_follow": { 
    title: "Instagram Follower werden",
    ctaText: "Jetz Folgen",
    icon: Insta,
  },
  "$newsletter_signup": { 
    title: "Newsletter abonnieren", 
    ctaText: "Abonnieren",
    icon: Newsletter,
  },
  "$birthday": {
    title: "Dein Geburtstag", 
    ctaText: "Geburtstag hinzufügen", 
    icon: Birthday,
  }
};

export const PointStatus = ({ title, className }) => {
  return <div className={[styles.pointStatus, className].join(" ")}>
    {title && <span>{title}</span>}
    {!title && <span>Dein aktueller Punktestand</span>}
    <div className={styles.points}>
      <span data-lion-points><RoundedLoader /></span> Olav Punkte
    </div>
  </div>;
};

const mapRules = (loyaltyLion) => {
  console.log("mapping rules");
  return (loyaltyLion?.program?.rules|| []).map((rule) => ({
    id: rule.id,
    title: rule.title,
    name: rule.name, 
    points: rule.loyaltyTierOverrides.find((tier) => tier.id === loyaltyLion.customer.loyaltyTierMembership.loyaltyTierId)?.value || rule.value,
    completed: loyaltyLion.customer.completedRules.find((completed) => completed.ruleId === rule.id) || loyaltyLion.customer.ruleContext.find((context) => context.ruleId === rule.id)?.limitReached
  })).filter((rule) => ruleOverrides[rule.name]).map((rule) => ({
    ...rule,
    title: ruleOverrides[rule.name].title || rule.title,
    icon: ruleOverrides[rule.name].icon,
    ctaText: ruleOverrides[rule.name].ctaText,
  }));
};

const Rule = (props) => { 
  const { id, completed, points, icon, title, ctaText, onClick } = props; 

  return <div className={styles.ruleWrap}>
    <div className={styles.rule}>
      <div className={styles.ruleInner}>
        <div className={styles.ruleIcon}>
          <img src={icon} /> 
        </div>

        <div className={styles.ruleTitle}>
          {title}
        </div>
        <div className={styles.rulePoints}>
          {points} Olav Punkte
        </div>
        <button className={styles.ruleCta} onClick={() => onClick(id) } disabled={completed}>
          {completed ? "Abgeschlossen" : ctaText}
        </button>
      </div>
    </div>
  </div>;
};

const CollectPoints = () => {
  const rulesRef = useRef(null);
  const { loyaltyLion } = useContext(GlobalContext);

  const rules = mapRules(loyaltyLion);

  console.log("rules", rules);

  const handleRuleClick = (ruleId) => { 
    rulesRef.current.querySelector(`[data-rule-id="${ruleId}"] button`)?.click();
  };

  return <AccountLayout
    title="Punkte sammeln"
    subtitle="Sammel Punkte und erhalte tolle Angebote"
    headerChildren={
      <PointStatus />
    }
  >

    <div className={styles.rulesWrap}>
      {rules.map((rule) => <Rule key={rule.id} {...rule} onClick={handleRuleClick} /> )}
    </div>
    <div data-lion-rules-list ref={rulesRef}></div>
  </AccountLayout>;
};

export default CollectPoints;
