import React from "react";
import * as styles from "./styles.mod.scss";
import { Check2, X } from "react-bootstrap-icons";

const BoxedTextInput = (props) => { 
  const { touched, errors } = props;
  const hasErrors = touched && errors;
  
  return <div className={`${styles.inputWrap}  ${hasErrors ? styles.hasErrors : ""}`}>
    <div className={`${styles.input}`}>
      <input {...props} />
      {hasErrors && <X className={styles.inputIcon} />}
      {!hasErrors && <Check2 className={styles.inputIcon} />}
    </div>
    {hasErrors ? <div className={styles.errors}>{errors}</div> : null}
  
  </div>;
};

export default BoxedTextInput;