import React, { useContext } from "react";
import AccountLayout from "components/account-layout";
import * as styles from "./styles.mod.scss";
import GlobalContext from "services/global-context";
import { OfferBox } from "../overview";
import getImagePath from "helpers/get-image-path";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

const SpecialOffers = () => {
  const { specialOffers } = useContext(GlobalContext);
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1023.98px)" });

  return <AccountLayout
    title="Spezielle Angebote"
    subtitle="Alle unsere Angebote im Überblick"
  >
    <div className={styles.wrap}>
      {isDesktop && specialOffers.map((offer) => <div className={styles.boxWrap} key={offer._id}>
        <OfferBox title={offer.title} subtitle={offer.subtitle} ctaCopy={offer.ctaText} ctaLink={offer.ctaLink} img={getImagePath(offer.image)} />
      </div>)}

      {isMobile && <Swiper
        slidesPerView={1}
        modules={[Pagination]}
        pagination={{ clickable: true }}
        breakpoints={{ 1024: { enabled: false } }}
      >
        {specialOffers.map((offer) => <SwiperSlide key={offer._id}>
          <div className={styles.boxWrap}>
            <OfferBox title={offer.title} subtitle={offer.subtitle} ctaCopy={offer.ctaText} ctaLink={offer.ctaLink} img={getImagePath(offer.image)} />
          </div>
        </SwiperSlide>)}
      </Swiper>}
    </div>
  </AccountLayout>;

};

export default SpecialOffers;
