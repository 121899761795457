import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { getOrders } from "services/shopify-account";
import AccountLayout from "components/account-layout";
import {  ChevronDown, ChevronUp } from "react-bootstrap-icons";
import * as styles from "./styles.mod.scss";
import Money from "components/money";

const LineItem = (props) => {
  const { lineItem } = props;

  return <div className={styles.lineItem}>
    <div className={styles.variantImage}>
      <img src={lineItem.variant.image.src} />
    </div>
    <div className={styles.lineItemDescription}>
      <div>
        <strong>{lineItem.variant.title}</strong>
        <span>Menge: {lineItem.quantity}</span>
        <span>Artikelnummer: {lineItem.variant.sku}</span>
      </div>
      <strong>Summe: <Money value={lineItem.discountedTotalPrice.amount} currency={lineItem.discountedTotalPrice.currencyCode} /></strong>
    </div>
  </div>;
};

const Order = (props) => {
  const { order, open, setOpen } = props;
  const lineItems = order.lineItems.edges.map((edge) => edge.node);

  console.log("lineItems", lineItems);
  return  <Card key={order.id} className={styles.card}>
    <Accordion.Toggle as={Card.Header} eventKey={order.id}
      onClick={() => setOpen({
        ...open, [order.id]: !open[order.id]
      })} className={styles.cardHeader}>
      <div className={styles.orderDate}>
        <strong>{new Date(order.processedAt).toLocaleDateString()}</strong><br/>
        <span className={styles.sub}>{order.lineItems.edges.length} Artikel</span>
      </div>
      <div className={styles.orderTotal}>
        <strong>{order.fulfillmentStatus}</strong><br/>
        <span className={styles.sub}>
          <Money
            value={order.totalPriceV2.amount}
            currency={order.totalPriceV2.currencyCode} />
        </span>
      </div>
      <span className={styles.accordionIcon}>{open[order.id] !== true ? <ChevronDown/> : <ChevronUp/>} </span>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey={order.id}>
      <Card.Body>
        <div className={styles.orderBody}>
          <ul className={styles.orderSteps}>
            <li>
              <div className={styles.stepNumber}>
              1
              </div>
              <div className={styles.stepDescription}>
                <div>Bestellung Erhalten</div>
                <span>Super! Wir haben deine Bestellung erhalten.</span>
              </div>
            </li>
            <li>
              <div className={styles.stepNumber}>
              2
              </div>
              <div className={styles.stepDescription}>
                <div>Bearbeitung Abgeschlossen</div>
                <span>Deine Bestellung ist versandfertig.</span>
              </div>
            </li>
            <li>
              <div className={styles.stepNumber}>
              3
              </div>
              <div className={styles.stepDescription}>
                <div>Versandt</div>
                <span>Prüfen</span>
              </div>
            </li>
            <li>
              <div className={`${styles.stepNumber} ${styles.stepNumberCurrent}`}>
              4
              </div>
              <div className={styles.stepDescription}>
                <div>Geliefert</div>
              </div>
            </li>
          </ul>

          <h4>Übersicht</h4>
          {lineItems.map((lineItem) => <LineItem lineItem={lineItem} key={lineItem.variant.id} />)}
        </div>

      </Card.Body>
    </Accordion.Collapse>
  </Card>;
};

const AccountOrders = () => {
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState({});

  useEffect(() => {
    getOrders().then((response) => {
      if (response) {
        const { data: { customer: { orders: { edges } } } } = response;
        setOrders(edges.map((edge) => edge.node));
      }
    });
  }, []);

  console.log("orders", orders);

  return <AccountLayout title="Meine Bestellungen" subtitle="In Deinen Benutzerkonto kannst Du Deine Bestellungen und Kontodaten direkt verwalten.">
    {orders.length > 0 && <Accordion>
      {orders.map((order) => <Order order={order} key={order.id} setOpen={setOpen} open={open} />)}
    </Accordion>}
  </AccountLayout>;
};

export default AccountOrders;
