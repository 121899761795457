import React, { useEffect, useMemo, useState } from "react";
import { getPersonalInformation, updateCustomer } from "services/shopify-account";
import AccountLayout from "components/account-layout";
import * as styles from "./styles.mod.scss";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import EditableField, { EditModeToggle } from "../editable-field";

const PersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string().required("is a required field"),
  lastName: Yup.string().required("is a required field"),
  address1: Yup.string().required("is a required field"),
  zip: Yup.string().required("is a required field"),
  city: Yup.string().required("is a required field")
});

const AccountDataSchema = Yup.object().shape({
  email: Yup.string().email().required("is a required field"),
  password: Yup.string()
});

const onShopifyResponse = (response, callback, setFieldError) => {
  if (response.data?.customerUpdate?.customerUserErrors?.length > 0) {
    const errors = response.data?.customerUpdate?.customerUserErrors;

    errors.forEach((error) => {
      const field = error.field[1];
      setFieldError(field, error.message);
    });
  } else {
    callback && callback();
  }
};

const AutoSubmit = ({ values, submitForm }) => {
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (!initialRender) {
      submitForm();
    } else {
      setInitialRender(false);
    }

  }, [values, submitForm]);

  return null;
};

const PersonalInformation = () => {
  const [customer, setCustomer] = useState(null);
  const [editMode, setEditMode] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const address = useMemo(() => (customer?.addresses?.edges || [])[0]?.node || {}, customer);

  useEffect(() => {
    getPersonalInformation().then((result) => {
      const { data: { customer } } = result;

      setCustomer(customer);

    });
  }, []);

  const toggleEditMode = (section) => {
    setEditMode({
      ...editMode, [section]: !editMode[section]
    });
    return false;
  };

  console.log("customer", customer);

  const onPISubmit = ({ firstName, lastName }, { setFieldError }) => {
    setSubmitting(true);

    updateCustomer({
      firstName, lastName
    }).then((response) => {
      onShopifyResponse(response, () => toggleEditMode("personalInformation"), setFieldError);
      setSubmitting(false);
    });
  };


  const onAccountDataSubmit = ({ email, password }, { setFieldError }) => {
    setSubmitting(true);

    updateCustomer({
      email, password
    }).then((response) => {
      onShopifyResponse(response, () => toggleEditMode("accountData"), setFieldError);
      setSubmitting(false);
      console.log("response", response);
    });
  };

  const onNewsletterSubmit = ({ acceptsMarketing }) => {
    setSubmitting(true);

    updateCustomer({ acceptsMarketing: acceptsMarketing === "true" }).then((response) => {
      onShopifyResponse(response);
      setSubmitting(false);
    });
  };


  return <AccountLayout
    title="Persönliche Daten"
    subtitle="Du kannst Deine nachstehenden Daten jederzeit bearbeiten.">

    {customer !== null && <><div className={styles.box}>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: customer.firstName,
          lastName: customer.lastName,
          address1: address.address1,
          zip: address.zip,
          city: address.city
        }}
        onSubmit={onPISubmit}
        validationSchema={PersonalInfoSchema}
      >
        {({ errors, touched, handleChange, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <EditModeToggle
              editMode={editMode.personalInformation}
              toggleEditMode={() => toggleEditMode("personalInformation")}
              submitting={submitting} />

            <div className={styles.field}>
              <label>Vorname*</label>
              <EditableField
                type="text"
                name="firstName"
                onChange={handleChange}
                errors={errors.firstName}
                touched={touched.firstName}
                value={values.firstName}
                edit={editMode.personalInformation}
                disabled={submitting}
              />
            </div>
            <div className={styles.field}>
              <label>Nachname*</label>
              <EditableField
                type="text"
                name="lastName"
                onChange={handleChange}
                errors={errors.lastName}
                touched={touched.lastName}
                value={values.lastName}
                edit={editMode.personalInformation}
                disabled={submitting}
              />
            </div>
            <div className={styles.field}>
              <label>Straße / Hausnummer*</label>
              <EditableField
                type="text"
                name="address1"
                onChange={handleChange}
                errors={errors.address1}
                touched={touched.address1}
                value={values.address1}
                edit={editMode.personalInformation}
              />
            </div>
            <div className={styles.field}>
              <label>PLZ*</label>
              <EditableField
                type="text"
                name="zip"
                onChange={handleChange}
                errors={errors.zip}
                touched={touched.zip}
                value={values.zip}
                edit={editMode.personalInformation}
                disabled={submitting}
              />
            </div>
            <div className={styles.field}>
              <label>Stadt*</label>
              <EditableField
                type="text"
                name="city"
                onChange={handleChange}
                errors={errors.city}
                touched={touched.city}
                value={values.city}
                edit={editMode.personalInformation}
                disabled={submitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>

    <h3 className={styles.title}>Deine Persönliche Daten</h3>
    <div className={styles.box}>
      <Formik
        enableReinitialize
        initialValues={{
          email: customer.email,
          password: "",
        }}
        onSubmit={onAccountDataSubmit}
        validationSchema={AccountDataSchema}
      >
        {({ errors, touched, handleChange, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <EditModeToggle
              editMode={editMode.accountData}
              toggleEditMode={() => toggleEditMode("accountData")}
              submitting={submitting} />
            <div className={styles.field}>
              <label>Email*</label>
              <EditableField
                type="text"
                name="email"
                onChange={handleChange}
                errors={errors.email}
                touched={touched.email}
                value={values.email}
                edit={editMode.accountData}
                disabled={submitting}
              />
            </div>
            <div className={styles.field}>
              <label>Passwort*</label>
              <EditableField
                type="password"
                name="password"
                onChange={handleChange}
                errors={errors.password}
                touched={touched.password}
                value={values.password}
                edit={editMode.accountData}
                disabled={submitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>

    <h3 className={styles.title}>Newsletter</h3>
    <div className={styles.box}>
      <Formik
        enableReinitialize
        initialValues={{ acceptsMarketing: customer.acceptsMarketing ? "true" : "false" }}
        onSubmit={onNewsletterSubmit}
      >
        {({ touched, values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {touched.acceptsMarketing}
            <div className={styles.radio}>
              <label>
                <Field type="radio" name="acceptsMarketing" value="true" disabled={submitting} />
                  Ja, ich möchte spannende News erhalten
              </label>
              <label>
                <Field type="radio" name="acceptsMarketing" value="false" disabled={submitting} />
                  Nein, ich möchte keine spannenden News erhalten
              </label>
            </div>
            <AutoSubmit values={values} submitForm={handleSubmit} />
          </form>
        )}
      </Formik>
    </div></>}
  </AccountLayout>;
};

export default PersonalInformation;
