import React, { useRef } from "react";
import AccountLayout from "components/account-layout";
import * as styles from "./styles.mod.scss";
import InviteFriendsBanner from "assets/images/account/invite_friend.png";
import InviteFriendsBannerMobile from "assets/images/account/invite_friend_mobile.png";

const InviteFriends = () => { 
  const rulesRef = useRef(null);

  const handleClick = () => { 
    rulesRef.current.querySelector(".lion-rule-item--\\$referral button").click();
  };

  return <AccountLayout 
    title="Freunde werben"
    subtitle="Hier kannst du Freunde einladen un 30€ bekommen."
  > 

    <div className={styles.bannerWrap}>
      <img src={InviteFriendsBanner} className={styles.desktop} />
      <img src={InviteFriendsBannerMobile} className={styles.mobile}/>

      <div className={styles.bannerCopy}>
        <h2>Lade deine freunde zum Kochen ein</h2>
        <div className={styles.bannerSubtitle}>Freunde einladen und 30€ erhalten</div>
        <div className={styles.bannerCtaWrap}>
          <button className={styles.bannerCta} onClick={handleClick}>Los geht’s</button>
        </div>
      </div>
    </div>
    <div>
      <div className={styles.steps}>
        <div className={styles.step}>
          <div className={styles.stepNumber}>1.</div>
          <div className={styles.stepCopy}>
            Trage deine E-Mail Adresse ein und lade deine Freunde ganz einfach per Mail, Messenger oder Facebook ein, um den Olav Empfehlungs-Code zu teilen.
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.stepNumber}>2.</div>
          <div className={styles.stepCopy}>
          Stelle sicher, dass Dein Freund oder Deine Freundin den Olav Empfehlungs-Code beim Kauf im Olav Shop verwendet.
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.stepNumber}>3.</div>
          <div className={styles.stepCopy}>
          Nachdem Deine Freundin oder Freund über deine Einladung bestellt hat, schreiben wir auch dir 30€ auf deine nächste Bestellung gut.
          </div>
        </div>
      </div>
    </div>
    <div data-lion-rules-list ref={rulesRef}></div>
  </AccountLayout>;
};

export default InviteFriends;