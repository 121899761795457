import { useState, useEffect } from "react";
import { withAccessToken } from "./shopify-account";

const getLionToken = async () => { 
  const response = await withAccessToken((token) => 
    fetch(`https://europe-west3-myolav.cloudfunctions.net/getCustomerToken?shopifyToken=${token}`, 
      { 
        method: "GET", 
        cache: "force-cache",
        headers: { "Content-Type": "application/json" }
      }
    )
  );
  
  if (response?.ok) { 
    const json = await response.json();
  
    return json; 
  }
  
  return null;
};

const getLoyaltyTier = ({ customer, program }) => { 
  return program.loyaltyTiers.find((tier) => tier.id === customer.loyaltyTierMembership.loyaltyTierId)?.name;
};

const useLoyaltyLion = (currentCustomer) => { 
  const [config, setConfig] = useState(null); 
  let interval = null;

  useEffect(() => {
    if (!currentCustomer) return;
    
    getLionToken().then((response) => { 
      if (response !== null) { 
        console.log(response);
  
        window.loyaltylion.authenticateCustomer({
          customer: { 
            id: response.id,
            email: response.email
          },
          auth: { 
            date: response.date,
            token: response.token
          }
        });

        interval = setInterval(() => {
          if (window.loyaltylion.customer && !window.loyaltylion.customer.guest){
            const { customer, program } = window.loyaltylion;
              
            console.log({
              customer, program 
            });
            setConfig({
              customer, program, tier: getLoyaltyTier(window.loyaltylion)
            });
            clearInterval(interval);
          } else if (window.loyaltylion.guest) { 
            setConfig(null);
            clearInterval(interval);
          }
        }, 50);
      }
    });

    return () => { 
      clearInterval(interval);
    };
  }, [currentCustomer]);

  return config;
};

export default useLoyaltyLion;