import React from "react";
import BoxedTextInput from "components/boxed-text-input";
import RoundedLoader from "components/rounded-loader";
import * as styles from "./styles.mod.scss";

const EditableField = (props) => {
  const { type, edit, value } = props;

  const newProps = {
    ...props, edit: undefined
  };

  return edit ? <BoxedTextInput {...newProps} /> : <div>{type === "password" ? "*****" : value}</div>;
};

export const EditModeToggle = (props) => {
  const { editMode, toggleEditMode, submitting } = props;

  return (
    <div className={styles.editWrap}>
      {editMode && submitting  ? <RoundedLoader /> : null}
      {!editMode && !submitting && <button onClick={toggleEditMode} type="button" className={styles.edit}>
            Bearbeiten
      </button>}
      {editMode && !submitting && <button type="submit" className={styles.edit}>
            Speichern
      </button>}
    </div>
  );
};


export default EditableField;
