import React, {  } from "react";
import BaseLayout from "layouts/base-layout";
import "scss/main.scss";
import "scss/vendor/carousels.scss";
import { Router } from "@reach/router";
import PrivateRoute from "components/private-route";
import AccountOverview from "components/account/overview";
import AccountOrders from "components/account/orders";
import PersonalInformation from "components/account/personal-information";
import CollectPoints from "components/account/collect-points";
import VIPStatus from "components/account/vip-status";
import RedeemPoints from "components/account/redeem-points";
import SpecialOffers from "components/account/special-offers";
import InviteFriends from "../components/account/invite-friends";
import MetaTags from "components/seo";


export default function Account(props) {
  const { settings, specialOffers } = props.pageContext;

  console.log("rendering", specialOffers);
  return (
    <BaseLayout
      data={{
        ...settings, specialOffers
      }}
    >

      <MetaTags meta={{ 
        siteTitle: "Olav - Kontoseite",
        shareImage: "https://cdn.sanity.io/images/xhnfo1ay/development/1202f8f77b373a364c0909d8f2bed1c32e1b12dc-1000x749.jpg", 
        metaDescription: "Deine Benutzerkontendetails" 
      }} />

      <Router basepath="/account">
        <PrivateRoute path="/overview" component={AccountOverview}></PrivateRoute>
        <PrivateRoute path="/orders" component={AccountOrders}></PrivateRoute>
        <PrivateRoute path="/personal-information" component={PersonalInformation}></PrivateRoute>
        <PrivateRoute path="/collect-points" component={CollectPoints}></PrivateRoute>
        <PrivateRoute path="/vip-status" component={VIPStatus}></PrivateRoute>
        <PrivateRoute path="/redeem-points" component={RedeemPoints}></PrivateRoute>
        <PrivateRoute path="/special-offers" component={SpecialOffers}></PrivateRoute>
        <PrivateRoute path="/invite-friends" component={InviteFriends}></PrivateRoute>

        <AccountOverview path="/" />
      </Router>
    </BaseLayout>
  );
}
